const useRequest = ( token, ip, port, path,  callback  ) => {

    const myHeaders = new Headers();
        
        const requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow"
        };
        
        fetch(`${location.protocol}//${ip}:${port}/${path}`, requestOptions)
          .then((response) => response.json())
          .then((result) => {

            if( result.error ) {
                const err = new Error(result.error);
                return err
            }

            callback( result );

          } )
          .catch((error) => console.error(error));
}

const usePostRequest = ( token, ip, port, path, data,  callback  ) => {

    const myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + token );
        
        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          redirect: "follow",
          body: data
        };
        
        fetch(`http://${ip}:${port}/${path}`, requestOptions)
          .then((response) => response.json())
          .then((result) => {

            if( result.error ) {
                const err = new Error(result.error);
                return err
            }

            callback( result );

          } )
          .catch((error) => console.error(error));
}

class WpApiError extends Error {
  constructor(message) {
    super(message);
    this.name = "WpApiError";
  }
}

const useWpApiRequest = ( url, method, data , callback ) => {
  wp.apiRequest({
    path: url,
    "method": method,
    data: data
  })
    .then((response) => {

      if( response.error ) {
        throw new WpApiError(response.error);
      }else{
        callback(response);
      }

    })
    .catch((error) => {
      console.error(error);
    });
}; 
 
export default useRequest;
export { WpApiError, useWpApiRequest };