import { render } from "react-dom";
import useListingContainers from "../hook/useListingContainers.jsx";

const handleClick = (e) => {
    e.preventDefault();
    document.querySelector(".server-dashboard-modal").classList.toggle("close");
    document.querySelector(".server-dashboard-modal").classList.toggle("active");
};

const ListingContainers = ( { terms_creds, term_name, term_id } ) => {

    const containers = useListingContainers( terms_creds );

    return ( 
        <div>
            <h3>{ term_name }</h3>
            <table className="wp-list-table widefat website-list-contain">
                <tr>
                    <td> <b>Servicio</b> </td>
                    <td> <b>Status</b> </td>
                    <td> <b>Container Type</b> </td>
                    <td> <b>Aplication</b> </td>
                </tr>

                { containers.map( container => (
                    <tr className={`docker-aplication ${ container.aplication }`} >
                        <td> {container.service} </td>
                        <td> <p className={"status " + container.status }> {container.status} </p> </td>
                        <td> { container.container_type } </td>
                        <td> { container.aplication } </td>
                        { container.container_type === "wp" && <td> <a href={`${location.href}&import=${container.aplication}&server_id=${term_id}`} > <button className="button button-primary"> Importar { container.aplication } </button> </a> </td> }
                    </tr>
                ) ) }

            </table>
            <button onClick={ e => handleClick(e) } className="button button-primary">Volver</button>
        </div>
     );
}
 
export default ListingContainers;