import React  from 'react';
import ReactDOM from 'react-dom/client';
import {createPortal} from 'react-dom';
import App from './App';
import AppBack from './AppBack';
import ListingContainers from './components/view-containers';

if(document.getElementById('my-front')){
	ReactDOM.createRoot(document.getElementById('my-front')).render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
);
}

if(document.getElementById('my-back')){
	ReactDOM.createRoot(document.getElementById('my-back')).render(
	<React.StrictMode>
		<AppBack />
	</React.StrictMode>
	);
}

if (document.body.classList.contains('toplevel_page_r33v_host')) {
	
	[...document.querySelectorAll(".server-dashboard .ver-container")].forEach( button => {

		const term_id = button.getAttribute('data-term-id');
		const terms_creds = button.getAttribute('data-setver-creds');
		const term_name = button.getAttribute('data-term-name');
		
		button.addEventListener('click', function(e){

			document.querySelector(".server-dashboard-modal").classList.toggle("close");
			document.querySelector(".server-dashboard-modal").classList.toggle("active");
			
			ReactDOM.createRoot(document.querySelector(".server-dashboard-modal .inside")).render(
				<ListingContainers terms_creds={terms_creds} term_name={term_name} term_id={term_id} />
			);
		});

	} );

}

