import { useEffect, useState } from "react";
import useRequest, { useWpApiRequest } from "./useRequest";

const useListingContainers = ( terms_creds ) => {
    
    const [containers, setContainers] = useState([]);

    const stripped = terms_creds.split("&");

    const config = {} ; 
    
    stripped.forEach( item => {
        const splitted = item.split("=");
        config[splitted[0]] = splitted[1];
    } );

    function get_aplication( service ){
        return service.split("_")[1];
    }

    function what_contaner_is( container_type ){

        let salida = ""

        if (container_type.includes('wp')) {
            salida = "wp";
        } else if (container_type.includes('db')) {
            salida = "db";
        } else {
            salida = "custom";
        }

        return salida;
    }

    useEffect( () => {

        useWpApiRequest( 
            'reev-host/v1/docker-container/' , 
            'GET' , 
            { "ip" : config.ip,  "port": config.port, "username": config.username , "password": config.password } , 
            result => {

                if( ! result.active_containers ) {
                    console.error( "Error: ", "Respuesta del servidor errorea" );
                    return null

                }

                const containers = [];
                result.active_containers.forEach( container => {
                    container.aplication = get_aplication( container.service );
                    container.container_type = what_contaner_is( container.service );
                    container.server_id = result.server_id;
                    containers.push( container );
                } );

                setContainers( containers )
            }
        );
        
    }, [terms_creds] );


    return containers;
};

export default useListingContainers;